import { TableFilterType } from "types/baTypes";
import { CardSize, DETAIL_TAB_VISIBILITY, RecordItem, SelectOptions } from "types/common";

export const getSiteName = (userType?: USER_TYPE) => {
  return userType === USER_TYPE.STAFF ? "Sold Ave" : "Sold Ave";
};

export enum CellType {
  PEOPLE = "People",
  TEXT = "Text",
  URL = "Url",
  BOOLEAN = "Boolean",
  DATE = "Date",
  DATETIME = "DateTime",
  CURRENCY = "Currency",
  CHECKBOX = "Checkbox",
  FILE = "File",
  PHONE = "Phone",
  DEAL = "Deal",
  BADGE = "Badge",
  STATE = "State",
  ADDRESS = "Address",
  BUTTON = "Button",
  EMAIL = "Email",
  SELECT = "Select",
  SPACES = "Spaces",
  RATING = "Rating",
  COMPANY = "Company",
  TRANSCRIPT = "Transcript",
  NUMBER = "Number",
  PERCENT = "Percent",
  LONG_TEXT = "Long Text",
  PROGRESS = "Progress",
  SCORE = "Score",
  AUDIO = "Audio",
  PRODUCT = "Product",
  GENERIC_CELL = "Generic Cell",
  COORDINATE = "Coordinate",
  COLOR = "Color",
  JSON = "JSON",
  EXIST = "Exist",
  ICON = "Icon",
  BEFORE_AFTER = "BeforeAfter",
  STATUS = "Status"
}

export const STATIC_CELL_TYPES_OPTIONS = Object.entries(CellType)
  .filter(([, value]: [key: string, value: CellType]) =>
    [
      CellType.ADDRESS,
      CellType.RATING,
      CellType.TEXT,
      CellType.BOOLEAN,
      CellType.NUMBER,
      CellType.EXIST,
      CellType.DATE,
      CellType.DATETIME,
      CellType.PERCENT,
      CellType.EMAIL,
      CellType.PHONE,
      CellType.CURRENCY,
      CellType.URL
    ].includes(value)
  )
  .map(([key]) => ({
    title: CellType[key as keyof typeof CellType],
    value: CellType[key as keyof typeof CellType]
  }));

export const TABLE_CELL_TYPE_OPTIONS = Object.keys(CellType).map((key) => ({
  title: CellType[key as keyof typeof CellType],
  value: CellType[key as keyof typeof CellType]
}));

export enum AvatarSize {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl"
}

export enum BadgeColor {
  RED = "red",
  GRAY = "gray",
  ORANGE = "orange",
  GREEN = "green",
  OVERLAY = "overlay",
  WHITE = "white",
  CUSTOM = "custom",
  YELLOW = "yellow",
  BLUE = "blue"
}

export enum BadgeSize {
  "2XS" = "2xs",
  XS = "xs",
  SM = "sm"
}

export enum ButtonSize {
  XS = "xs",
  SM = "sm",
  MD = "md"
}

export enum ButtonIconPosition {
  LEFT = "left",
  RIGHT = "right"
}

export enum ButtonAppearance {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  NEUTRAL = "neutral",
  RED = "red"
}

export enum TooltipPosition {
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left"
}

export enum DropdownPosition {
  TOP_LEFT = "top-start",
  TOP_RIGHT = "top-end",
  BOTTOM_LEFT = "bottom-start",
  BOTTOM_RIGHT = "bottom-end",
  RIGHT_START = "right-start"
}

export enum FilterButtonType {
  FILTER = "filter",
  SORT = "sort",
  SELECT = "select"
}

export enum ViewOption {
  GRID = "grid",
  CARD = "card",
  MAP = "map",
  BOARD = "board",
  DETAIL_MAIN = "detail_main",
  MULTI_PAGES = "multi_pages",
  FORM = "form",
  NOTE = "note",
  LIST = "list",
  CALENDAR = "calendar",
  MATRIX = "matrix",
  ACTIVITY = "activity",
  TABLE_LITE = "table_lite",
  CONVERSATION = "conversation"
}

export enum DensityType {
  COMPACT = "compact",
  STANDARD = "standard",
  COMFORTABLE = "comfortable",
  AUTO = "auto"
}

export type RowDensity = {
  type: DensityType;
  value: number;
  label: string;
};

export type GroupByInfo = {
  enabled?: boolean;
  isCollapsed?: boolean;
  groupedColumns?: string[];
  firstColumn?: string;
};

export const DEFAULT_ROW_DENSITY: RowDensity = { type: DensityType.STANDARD, value: 84, label: "Standard" };

export const ROW_DENSITY_OPTIONS: RowDensity[] = [
  {
    type: DensityType.COMPACT,
    value: 60,
    label: "Compact"
  },
  DEFAULT_ROW_DENSITY,
  {
    type: DensityType.COMFORTABLE,
    value: 144,
    label: "Comfortable"
  }
];

export const CARD_SIZE_OPTIONS = [
  { title: "Small", value: CardSize.SMALL },
  { title: "Medium", value: CardSize.MEDIUM },
  { title: "Large", value: CardSize.LARGE },
  { title: "Extra Large", value: CardSize.EXTRA_LARGE }
];
export const DETAIL_HEADER_VIEW = "detail_header";
export type ViewModes = Exclude<ViewOption, ViewOption.DETAIL_MAIN | ViewOption.FORM>;
export type PageActionViews =
  | typeof ViewOption.CARD
  | typeof ViewOption.GRID
  | typeof ViewOption.NOTE
  | typeof ViewOption.BOARD
  | typeof ViewOption.MAP
  | typeof DETAIL_HEADER_VIEW;
export const FALLBACK_IMAGE_SRC = `common/fallback_transparent_thin.png`;
export const PRODUCT_PLACEHOLDER = `common/product-placeholder.png`;

export const PageActionViewOptions = [
  ViewOption.BOARD,
  ViewOption.CARD,
  DETAIL_HEADER_VIEW,
  ViewOption.GRID,
  ViewOption.MAP,
  ViewOption.NOTE
].map((view) => {
  return {
    value: view,
    title: view
  };
});

export enum ModalSize {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
  XXL = "xxl"
}

export enum LookupTypes {
  FOREIGN = "foreign",
  JOIN = "join",
  FOREIGN_EXTERNAL = "foreign_external" // The lookup level 0 table has a foreign key to the base table (currently only works with aggregate functions)
}

export enum CellGroupDirection {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical"
}

export enum ControlType {
  FILTER = "Filter",
  GROUP = "Group",
  SORT = "Sort"
}

export const emptySortOrder: Record<ViewOption, string> = {
  [ViewOption.GRID]: "0|aa",
  [ViewOption.MAP]: "0|aa",
  [ViewOption.CARD]: "0|aa",
  [ViewOption.BOARD]: "0|aa",
  [ViewOption.DETAIL_MAIN]: "0|aa",
  [ViewOption.FORM]: "0|aa",
  [ViewOption.NOTE]: "0|aa",
  [ViewOption.LIST]: "0|aa",
  [ViewOption.CALENDAR]: "0|aa",
  [ViewOption.MATRIX]: "0|aa",
  [ViewOption.ACTIVITY]: "0|aa",
  [ViewOption.MULTI_PAGES]: "0|aa",
  [ViewOption.TABLE_LITE]: "0|aa",
  [ViewOption.CONVERSATION]: "0|aa"
};

export const emptyHiddenState: Record<Exclude<ViewOption, ViewOption.FORM>, boolean> = {
  [ViewOption.GRID]: false,
  [ViewOption.MAP]: false,
  [ViewOption.CARD]: false,
  [ViewOption.BOARD]: false,
  [ViewOption.DETAIL_MAIN]: false,
  [ViewOption.NOTE]: false,
  [ViewOption.LIST]: false,
  [ViewOption.CALENDAR]: false,
  [ViewOption.MATRIX]: false,
  [ViewOption.ACTIVITY]: false,
  [ViewOption.MULTI_PAGES]: false,
  [ViewOption.TABLE_LITE]: false,
  [ViewOption.CONVERSATION]: false
};

export enum CrudActions {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
  DISPLAY = "display",
  CREATE_CUSTOM = "create_custom", // When view is a custom view
  BULK_UPDATE = "bulk_update"
}

export enum AdditionalFormActions {
  EMAIL = "email",
  BULK_EDIT = "bulk_edit",
  BULK_MATRIX_EDIT = "bulk__matrix_edit",
  EDIT_JSON = "edit_json",
  WEBHOOK_FORM = "webhook_form"
}

export const FORM_CREATE_ACTIONS = [CrudActions.CREATE, AdditionalFormActions.EMAIL];

// these are used to read column from records to populate prefillValues for Email/SMS Fields of Form
export const RECORD_EMAIL_SMS_TO_COLUMN_HEADERS = ["To", "Primary Contact"];
export const RECORD_EMAIL_SMS_FROM_COLUMN_HEADERS = ["From"];

// these are used in form view to find the column to populate prefillValues for Email/SMS Fields
export const FORM_EMAIL_SMS_FROM_COLUMN_HEADERS = ["From Contact"];
export const FORM_EMAIL_SMS_TO_COLUMN_HEADERS = ["To Contact"];

export const emptyLookupEntry = {
  lookupTableName: "",
  lookupColumns: [],
  lookupForeignKey: "",
  lookupColumnLabel: ""
};

export const SelectCellTypes = [
  CellType.SELECT,
  CellType.PEOPLE,
  CellType.DEAL,
  CellType.SPACES,
  CellType.COMPANY,
  CellType.PRODUCT,
  CellType.COLOR,
  CellType.BADGE,
  CellType.STATUS
];

export const InputCellTypes = [
  CellType.CURRENCY,
  CellType.URL,
  CellType.EMAIL,
  CellType.PHONE,
  CellType.NUMBER,
  CellType.PERCENT,
  CellType.TEXT
];

export const InlineEditingCellTypes: CellType[] = [
  CellType.CURRENCY,
  CellType.URL,
  CellType.EMAIL,
  CellType.NUMBER,
  CellType.PERCENT,
  CellType.TEXT,
  CellType.BOOLEAN,
  CellType.LONG_TEXT,
  CellType.DATE,
  CellType.DATETIME,
  CellType.RATING,
  CellType.FILE,
  CellType.BADGE,
  CellType.PHONE,
  CellType.ICON,
  CellType.GENERIC_CELL,
  ...SelectCellTypes
];

export enum GridRowSize {
  SM = "sm",
  LG = "lg"
}

export enum Size {
  SM = "sm",
  MD = "md",
  LG = "lg"
}

export const FileCellProps = {
  cardClassName: "min-w-[44px] w-11 h-11",
  cardProps: {
    className: "object-cover rounded h-11 !w-11",
    width: 44,
    height: 44
  },
  detailClassName: "min-w-[98.67px] w-[98.67px] h-[74px]",
  detailProps: {
    className: "object-cover rounded-xl h-[74px] !w-[98.67px]",
    width: 98.67,
    height: 74
  },
  sizeClassName: {
    [Size.SM as string]: "min-w-[44px] w-11 h-11",
    [Size.MD]: "min-w-[66px] w-[66px] h-[66px]",
    [Size.LG]: "min-w-[101px] w-[101px] h-[101px]"
  },
  sizeProps: {
    [Size.SM as string]: {
      className: "object-cover rounded h-11 !w-11",
      width: 44,
      height: 44
    },
    [Size.MD]: {
      className: "object-cover rounded h-[66px] !w-[66px]",
      width: 66,
      height: 66
    },
    [Size.LG]: {
      className: "object-cover rounded h-[101px] !w-[101px]",
      width: 101,
      height: 101
    }
  }
};

export enum FILTER_OPERATOR {
  EQUALS = "equals",
  NOT_EQUALS = "neq",
  GREATER_THAN = "greater than",
  LESS_THAN = "less than",
  CONTAINS = "contains",
  CONTAINED_BY = "containedby",
  CURRENT_USER = "current_user",
  EMPTY = "empty",
  NOT_EMPTY = "not empty",
  IN = "in",
  CURRENT_DEAL = "current_deal",
  CURRENT_RECORD = "current_record",
  OR = "or_group",
  CUSTOM_RECORD = "custom_record",
  DOES_NOT_CONTAIN = "does not contain",
  GREATER_THAN_EQUALS = "greater than and equals",
  LESS_THAN_EQUALS = "less than and equals",
  NOT_IN = "not in"
}

export const FILTER_OPERATOR_NEED_VALUE = [
  FILTER_OPERATOR.CONTAINS,
  FILTER_OPERATOR.EQUALS,
  FILTER_OPERATOR.NOT_EQUALS,
  FILTER_OPERATOR.GREATER_THAN,
  FILTER_OPERATOR.LESS_THAN,
  FILTER_OPERATOR.IN,
  FILTER_OPERATOR.DOES_NOT_CONTAIN,
  FILTER_OPERATOR.GREATER_THAN_EQUALS,
  FILTER_OPERATOR.LESS_THAN_EQUALS,
  FILTER_OPERATOR.NOT_IN
];

export enum FILTER_OPERATOR_SEARCH_PARAMS {
  EQUALS = "eq",
  NOT_EQUALS = "neq",
  GREATER_THAN = "gt",
  LESS_THAN = "lt",
  CONTAINS = "contains",
  CURRENT_USER = "cusr",
  EMPTY = "null",
  NOT_EMPTY = "notnull",
  IN = "in",
  OR = "or",
  DOES_NOT_CONTAIN = "dnc",
  GREATER_THAN_EQUALS = "gte",
  LESS_THAN_EQUALS = "lte",
  NOT_IN = "notin"
}

export const FILTER_OPERATOR_OPTIONS = [
  { title: "equals", value: FILTER_OPERATOR.EQUALS },
  { title: "does not equal", value: FILTER_OPERATOR.NOT_EQUALS },
  { title: "greater than", value: FILTER_OPERATOR.GREATER_THAN },
  { title: "greater than and equals", value: FILTER_OPERATOR.GREATER_THAN_EQUALS },
  { title: "less than and equals", value: FILTER_OPERATOR.LESS_THAN_EQUALS },
  { title: "less than", value: FILTER_OPERATOR.LESS_THAN },
  { title: "contains", value: FILTER_OPERATOR.CONTAINS },
  { title: "contained by", value: FILTER_OPERATOR.CONTAINED_BY },
  { title: "in", value: FILTER_OPERATOR.IN },
  { title: "not in", value: FILTER_OPERATOR.NOT_IN },
  { title: "equals current user", value: FILTER_OPERATOR.CURRENT_USER, isAdminOnly: true },
  { title: "equals current deal", value: FILTER_OPERATOR.CURRENT_DEAL, isAdminOnly: true },
  { title: "equals current record", value: FILTER_OPERATOR.CURRENT_RECORD, isAdminOnly: true },
  { title: "equals custom record", value: FILTER_OPERATOR.CUSTOM_RECORD, isAdminOnly: true },
  { title: "is empty", value: FILTER_OPERATOR.EMPTY },
  { title: "is not empty", value: FILTER_OPERATOR.NOT_EMPTY },
  { title: "OR group", value: FILTER_OPERATOR.OR, isAdminOnly: true },
  { title: "does not contain", value: FILTER_OPERATOR.DOES_NOT_CONTAIN }
];

export const TEXT_FILTER_OPTIONS = [
  { title: "equals", value: FILTER_OPERATOR.EQUALS },
  { title: "does not equal", value: FILTER_OPERATOR.NOT_EQUALS },
  { title: "contains", value: FILTER_OPERATOR.CONTAINS },
  { title: "is empty", value: FILTER_OPERATOR.EMPTY },
  { title: "is not empty", value: FILTER_OPERATOR.NOT_EMPTY },
  { title: "does not contain", value: FILTER_OPERATOR.DOES_NOT_CONTAIN }
];

export const TEXT_ARRAY_FILTER_OPTIONS = [
  { title: "contains", value: FILTER_OPERATOR.CONTAINS },
  { title: "contained by", value: FILTER_OPERATOR.CONTAINED_BY },
  { title: "is empty", value: FILTER_OPERATOR.EMPTY },
  { title: "is not empty", value: FILTER_OPERATOR.NOT_EMPTY },
  { title: "does not contain", value: FILTER_OPERATOR.DOES_NOT_CONTAIN }
];

export const NUMERIC_FILTER_OPTIONS = [
  { title: "equals", value: FILTER_OPERATOR.EQUALS },
  { title: "does not equal", value: FILTER_OPERATOR.NOT_EQUALS },
  { title: "greater than", value: FILTER_OPERATOR.GREATER_THAN },
  { title: "greater than and equals", value: FILTER_OPERATOR.GREATER_THAN_EQUALS },
  { title: "less than", value: FILTER_OPERATOR.LESS_THAN },
  { title: "less than and equals", value: FILTER_OPERATOR.LESS_THAN_EQUALS },
  { title: "is empty", value: FILTER_OPERATOR.EMPTY },
  { title: "is not empty", value: FILTER_OPERATOR.NOT_EMPTY }
];

export const DATE_FILTER_OPTIONS = [
  { title: "greater than", value: FILTER_OPERATOR.GREATER_THAN },
  { title: "greater than and equals", value: FILTER_OPERATOR.GREATER_THAN_EQUALS },
  { title: "less than", value: FILTER_OPERATOR.LESS_THAN },
  { title: "is empty", value: FILTER_OPERATOR.EMPTY },
  { title: "is not empty", value: FILTER_OPERATOR.NOT_EMPTY }
];

export const BOOLEAN_OPTION = {
  value: FILTER_OPERATOR.EQUALS,
  title: FILTER_OPERATOR.EQUALS
};
export const EXCLUDED_FILTER_CELL_TYPES = [
  CellType.GENERIC_CELL,
  CellType.FILE,
  CellType.BEFORE_AFTER,
  CellType.ICON,
  CellType.COORDINATE
];

// Used for People, Company, Project, Space, Status cells
export const FILTER_OPERATOR_FOR_SPECIAL_CELLS = [
  { title: "in", value: FILTER_OPERATOR.IN },
  { title: "equals", value: FILTER_OPERATOR.EQUALS },
  { title: "does not equal", value: FILTER_OPERATOR.NOT_EQUALS },
  { title: "is empty", value: FILTER_OPERATOR.EMPTY },
  { title: "is not empty", value: FILTER_OPERATOR.NOT_EMPTY }
];

export const emptyColumnFilterEntry = {
  id: "",
  filterLookupPath: undefined,
  filterField: "",
  filterValue: "",
  filterOperator: FILTER_OPERATOR.EQUALS
};

/** Returned by any input field if value is removed/cleared from existing value */
export const EMPTY_FIELD = "EMPTY";

export const TEXT_TYPE_CELLS = [
  CellType.TEXT,
  CellType.URL,
  CellType.NUMBER,
  CellType.PERCENT,
  CellType.CURRENCY,
  CellType.PHONE,
  CellType.EMAIL,
  CellType.LONG_TEXT,
  CellType.DATE,
  CellType.DATETIME,
  CellType.PROGRESS,
  CellType.SCORE,
  CellType.RATING,
  CellType.PERCENT,
  CellType.AUDIO,
  CellType.BOOLEAN,
  CellType.BADGE,
  CellType.JSON
];

export const SINGLE_CLICK_COPY_CELLS = [CellType.URL, CellType.EMAIL, CellType.TEXT];

export const AGGREGATE_COLUMN_TYPES = [CellType.CURRENCY, CellType.NUMBER];

export const CENTERED_CELL_COLUMN_TYPES = [CellType.BOOLEAN, CellType.COLOR];

export const ARROW_DISPLAY_CELL_TYPES = [CellType.NUMBER, CellType.PERCENT, CellType.CURRENCY];

export enum TOAST_TYPE {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info"
}

export const US_STATES = [
  {
    title: "Alabama",
    value: "AL"
  },
  {
    title: "Alaska",
    value: "AK"
  },
  {
    title: "Arizona",
    value: "AZ"
  },
  {
    title: "Arkansas",
    value: "AR"
  },
  {
    title: "California",
    value: "CA"
  },
  {
    title: "Colorado",
    value: "CO"
  },
  {
    title: "Connecticut",
    value: "CT"
  },
  {
    title: "Delaware",
    value: "DE"
  },
  {
    title: "Florida",
    value: "FL"
  },
  {
    title: "Georgia",
    value: "GA"
  },
  {
    title: "Hawaii",
    value: "HI"
  },
  {
    title: "Idaho",
    value: "ID"
  },
  {
    title: "Illinois",
    value: "IL"
  },
  {
    title: "Indiana",
    value: "IN"
  },
  {
    title: "Iowa",
    value: "IA"
  },
  {
    title: "Kansas",
    value: "KS"
  },
  {
    title: "Kentucky",
    value: "KY"
  },
  {
    title: "Louisiana",
    value: "LA"
  },
  {
    title: "Maine",
    value: "ME"
  },
  {
    title: "Maryland",
    value: "MD"
  },
  {
    title: "Massachusetts",
    value: "MA"
  },
  {
    title: "Michigan",
    value: "MI"
  },
  {
    title: "Minnesota",
    value: "MN"
  },
  {
    title: "Mississippi",
    value: "MS"
  },
  {
    title: "Missouri",
    value: "MO"
  },
  {
    title: "Montana",
    value: "MT"
  },
  {
    title: "Nebraska",
    value: "NE"
  },
  {
    title: "Nevada",
    value: "NV"
  },
  {
    title: "New Hampshire",
    value: "NH"
  },
  {
    title: "New Jersey",
    value: "NJ"
  },
  {
    title: "New Mexico",
    value: "NM"
  },
  {
    title: "New York",
    value: "NY"
  },
  {
    title: "North Carolina",
    value: "NC"
  },
  {
    title: "North Dakota",
    value: "ND"
  },
  {
    title: "Ohio",
    value: "OH"
  },
  {
    title: "Oklahoma",
    value: "OK"
  },
  {
    title: "Oregon",
    value: "OR"
  },
  {
    title: "Pennsylvania",
    value: "PA"
  },
  {
    title: "Rhode Island",
    value: "RI"
  },
  {
    title: "South Carolina",
    value: "SC"
  },
  {
    title: "South Dakota",
    value: "SD"
  },
  {
    title: "Tennessee",
    value: "TN"
  },
  {
    title: "Texas",
    value: "TX"
  },
  {
    title: "Utah",
    value: "UT"
  },
  {
    title: "Vermont",
    value: "VT"
  },
  {
    title: "Virginia",
    value: "VA"
  },
  {
    title: "Washington",
    value: "WA"
  },
  {
    title: "West Virginia",
    value: "WV"
  },
  {
    title: "Wisconsin",
    value: "WI"
  },
  {
    title: "Wyoming",
    value: "WY"
  }
];

// ##HARDCODED - Actions are currently linked to the name and this cannot be made editable
export enum UI_ACTIONS {
  INVITE = "Invite",
  DELETE = "Delete",
  DUPLICATE = "Duplicate",
  FEATURED = "Set as Featured",
  EMAIL = "Create Email",
  SMS = "Create SMS",
  COPYURL = "Copy URL",
  SEND_MAGIC_LINK = "Send Magic Link",
  DOWNLOAD = "Download",
  COPY_RAW_URL = "Copy Raw URL",
  EDIT_MENU = "Edit Menu",
  LOCK_RECORD = "Lock Record",
  SEND_TO_TOP = "Send to Top",
  SEND_TO_BOTTOM = "Send to Bottom",
  EDIT = "Edit",
  EDIT_TILE_COMPONENTS = "Edit Tile Components",
  PIN = "Pin",
  UNPIN = "Unpin",
  MAKE_WEBHOOK = "Make Webhook",
  OPEN_IN_SIDEBAR = "Open in Sidebar",
  SET_DEFAULT_TABLE_PAGE = "Set Default Table Page"
}

export enum USER_TYPE {
  STAFF = "Staff",
  BUYER = "Buyer"
}

export const sortOptions = [
  { value: "asc", title: "Ascending", icon: "arrow-up-narrow-wide" },
  { value: "desc", title: "Descending", icon: "arrow-down-wide-narrow" }
] as SelectOptions;

export const CellTypeDisableSort = [CellType.URL, CellType.BUTTON, CellType.GENERIC_CELL, CellType.JSON];

export const ActionType = {
  DANGER: "Danger",
  POSITIVE: "Positive",
  LINK: "Link",
  WEBHOOK: "Webhook",
  RENAME: "Rename",
  MENU_EDIT: "MenuEdit",
  MENU_ITEM_EDIT: "MenuItemEdit",
  LOCK: "Lock",
  REGEN_THUMBNAIL: "RegenerateThumbnail",
  CUSTOM: "Custom" // will handle it's own onClick
};

export enum FileType {
  IMAGE = "Image",
  VIDEO = "Video",
  DOCUMENT = "Document",
  AUDIO = "Audio",
  DRAWING = "Drawing"
}

export const PLACES_ADDRESS_FIELDS = ["address1", "address2", "city", "state", "zip", "longitude", "latitude"];

export enum SidebarContainer {
  Sidebar = "Sidebar",
  Modal = "Modal",
  Collapsible = "Collapsible"
}

export const PAGINATION_ROW_COUNT_OPTIONS = [50, 100, 200];

export const CELL_TYPES_WITH_FILES = [CellType.PEOPLE, CellType.COMPANY];

export const INITIAL_PAGINATION_STATE = {
  currentPage: 1,
  totalCount: 0,
  pageSize: 200
};

export const INITIAL_RECORD_PAGINATION_STATE = {
  currentPage: 1,
  totalCount: 0,
  pageSize: 500
};

export const INITIAL_GRID_PAGINATION_MODEL = {
  pageSize: 100,
  page: 0
};

export const KEY_TO_BLOCK_IN_SEARCH_SELECT = ["Enter", "Escape", "ArrowDown", "ArrowUp", "Home", "End"];

export enum PeopleType {
  Pro = "Pro",
  Staff = "Staff",
  Vendor = "Vendor",
  Client = "Client",
  Lead = "Lead",
  Seller = "Seller",
  Bot = "Bot",
  Buyer = "Buyer",
  Lender = "Lender"
}

export const FADE_IN_ANIMATION_SETTINGS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.2 }
};

export const FADE_DOWN_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, y: -10 },
  show: { opacity: 1, y: 0, transition: { type: "spring" } }
};

export const FADE_UP_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, y: 10 },
  show: { opacity: 1, y: 0, transition: { type: "spring" } }
};

export const PeopleTypeAvailableForIcon = Object.values(PeopleType);

export enum ButtonType {
  Button = "Button",
  IconButton = "Icon Button"
}

export const CALLBACK_UI_ACTIONS = [
  UI_ACTIONS.DELETE,
  UI_ACTIONS.DUPLICATE,
  UI_ACTIONS.FEATURED,
  UI_ACTIONS.PIN,
  UI_ACTIONS.UNPIN
];

export const CALLBACK_UI_ACTION_TYPES = [ActionType.LOCK];

export const ALGOLIA_ACTIONS_TYPE = {
  CREATE: "create"
};

export const ALGOLIA_MENU_ITEMS_INDEX = "airstudio_menu_items";
export const ALGOLIA_MASTER_INDEX = "astudio_master_index";

export const SPECIAL_DEFAULTS = {
  CURRENT_RECORD_ID: "Current Record ID",
  CURRENT_DEAL_ID: "Current Deal ID",
  CURRENT_USER_ID: "Current User ID",
  NEW_RECORD_ID: "New Record ID",
  EXTERNAL_RECORD: "External Record",
  CURRENT_CONTACT_USER_ID: "Current Contact USER ID" // This is only used internally depending on the db column type which has to be uuid
};

export const SPECIAL_DEFAULTS_VALUES = [
  SPECIAL_DEFAULTS.CURRENT_DEAL_ID,
  SPECIAL_DEFAULTS.CURRENT_RECORD_ID,
  SPECIAL_DEFAULTS.CURRENT_USER_ID,
  SPECIAL_DEFAULTS.NEW_RECORD_ID
];

export const SPECIAL_DEFAULTS_OPTIONS = [
  {
    value: SPECIAL_DEFAULTS.CURRENT_RECORD_ID,
    title: SPECIAL_DEFAULTS.CURRENT_RECORD_ID
  },
  {
    value: SPECIAL_DEFAULTS.CURRENT_DEAL_ID,
    title: SPECIAL_DEFAULTS.CURRENT_DEAL_ID
  },
  {
    value: SPECIAL_DEFAULTS.CURRENT_USER_ID,
    title: SPECIAL_DEFAULTS.CURRENT_USER_ID
  }
];

export const BASIC_SPECIAL_DEFAULTS_OPTIONS = [
  {
    value: SPECIAL_DEFAULTS.CURRENT_RECORD_ID,
    title: SPECIAL_DEFAULTS.CURRENT_RECORD_ID
  },
  {
    value: SPECIAL_DEFAULTS.CURRENT_DEAL_ID,
    title: SPECIAL_DEFAULTS.CURRENT_DEAL_ID
  },
  {
    value: SPECIAL_DEFAULTS.CURRENT_USER_ID,
    title: SPECIAL_DEFAULTS.CURRENT_USER_ID
  },
  {
    value: SPECIAL_DEFAULTS.EXTERNAL_RECORD,
    title: SPECIAL_DEFAULTS.EXTERNAL_RECORD
  }
];

export const FORM_SPECIAL_DEFAULTS_OPTIONS = [
  {
    value: SPECIAL_DEFAULTS.CURRENT_RECORD_ID,
    title: SPECIAL_DEFAULTS.CURRENT_RECORD_ID
  },
  {
    value: SPECIAL_DEFAULTS.CURRENT_DEAL_ID,
    title: SPECIAL_DEFAULTS.CURRENT_DEAL_ID
  },
  {
    value: SPECIAL_DEFAULTS.CURRENT_USER_ID,
    title: SPECIAL_DEFAULTS.CURRENT_USER_ID
  },
  {
    value: SPECIAL_DEFAULTS.NEW_RECORD_ID,
    title: SPECIAL_DEFAULTS.NEW_RECORD_ID
  }
];

// To be expanded later if more generic cell types are added
const GENERIC_CELLS = [CellType.GENERIC_CELL] as const;

export type GENERIC_CELL_TYPES = (typeof GENERIC_CELLS)[number];

export const COLUMN_TYPES = ["Lookup", "Basic", "Formula", "Rollup"];

export const COLUMN_TYPES_OPTIONS = COLUMN_TYPES.map((colType) => ({
  value: colType,
  title: colType
}));

export const LINK_OPEN_LOCATIONS = {
  SIDEBAR: "sidebar",
  NEW_TAB: "new_tab",
  SAME_TAB: "same_tab"
};

export const LINK_OPEN_LOCATION_OPTIONS = [
  {
    title: "Open in Sidebar",
    value: LINK_OPEN_LOCATIONS.SIDEBAR
  },
  {
    title: "Open in New Tab",
    value: LINK_OPEN_LOCATIONS.NEW_TAB
  },
  {
    title: "Open in Same Tab",
    value: LINK_OPEN_LOCATIONS.SAME_TAB
  }
];

export enum PAGE_ACTION_VISIBILITY {
  PAGE = "page",
  BULK = "bulk",
  ALL = "all"
}

export enum WRITE_ROLLUP_FORMULAS {
  SUM = "SUM",
  COUNT = "COUNT"
}

export const WRITE_ROLLUP_FORMULA_OPTIONS = Object.keys(WRITE_ROLLUP_FORMULAS).map((formula) => ({
  value: formula,
  title: formula
}));

export enum GENERIC_CELL_LAYOUTS {
  DEFAULT = "default",
  COMMA_SEPARATED = "comma_separated",
  SEARCH_RESULT = "search_result",
  COMBINATION_CELL = "combination_cell"
}

export enum SIDEBAR_TYPE {
  ADMIN_COLUMN_EDIT = "admin_column_edit",
  BULK_EDIT = "bulk_edit",
  EDIT = "edit",
  ADD = "add"
}

/** IMP: This is map of the sidebarId's for the sidebars being used for generic/common sidebar component
 * It needs to be updated to have id if new sidebar type/handling is added to  components/Sidebar/index.tsx
 * It is used to hadndle the esc key event to only close the recently opened sidebar if multiple sidebar is opened
 * Also needs to update updateSidebarState function in TableActionsContext.tsx to handle adding of new sidebar type
 */
export enum STATIC_SIDEBAR_IDS {
  MAIN_SIDEBAR = "main_sidebar",
  ADD_SIDEBAR = "add_sidebar",
  PAGE_VIEW_CONFIG_EDIT_SIDEBAR = "page_view_config_edit_sidebar",
  MENU_CONFIG_EDIT_SIDEBAR = "menu_config_edit_sidebar",
  COLUMN_CONFIG_EDIT_SIDEBAR = "column_config_edit_sidebar",
  SEARCH_TABLE_CONFIG_EDIT_SIDEBAR = "search_table_config_edit_sidebar"
}

export enum ESCAPE_EVENT_RESOLVED_BY_COMP {
  SIDEBAR = "sidebar"
}

export enum COLUMN_RULE_OPERATORS {
  CONTAINS = "Contains",
  NOT_CONTAINS = "Does Not Contain",
  EQUALS = "Is Equal To",
  NOT_EQUALS = "Is Not Equal To",
  GREATER_THAN = "Is Greater Than",
  LESS_THAN = "Is Less Than",
  BLANK = "Is Blank",
  NOT_BLANK = "Is Not Blank",
  CHARACTER_LENGTH = "Character Length",
  STARTS_WITH = "Starts With",
  WITHIN_RECORD_VIEW = "Within Record View"
}

export const COLUMN_RULE_OPERATORS_WITHOUT_VALUE = [
  COLUMN_RULE_OPERATORS.BLANK,
  COLUMN_RULE_OPERATORS.NOT_BLANK,
  COLUMN_RULE_OPERATORS.WITHIN_RECORD_VIEW
];

export const COLUMN_RULE_OPERATORS_OPTIONS = Object.keys(COLUMN_RULE_OPERATORS).map((key) => ({
  value: COLUMN_RULE_OPERATORS[key as keyof typeof COLUMN_RULE_OPERATORS],
  title: COLUMN_RULE_OPERATORS[key as keyof typeof COLUMN_RULE_OPERATORS]
}));

const YELLOW = "Yellow";
const GREEN = "Green";
const RED = "Red";
const PINK = "Pink";
const BLUE = "Blue";
const GREY = "Grey";

export const FORMAT_COLOR = {
  YELLOW,
  GREEN,
  RED,
  PINK,
  BLUE,
  GREY
};

export const ALL_COLUMNS = "All Columns";

export const VIEWOPTION_OPTIONS = Object.keys(ViewOption).map((key) => ({
  value: ViewOption[key as keyof typeof ViewOption],
  title: ViewOption[key as keyof typeof ViewOption]
}));

export const FILTER_BY_COLUMN_TYPES = [CellType.CURRENCY, CellType.DATE, CellType.PERCENT];

export const GROUP_BY_EXPANDED = -1;
export const GROUP_BY_COLLAPSED = 0;

export const PAGE_USER_TYPES_OPTIONS = Object.keys(USER_TYPE).map((key) => ({
  value: USER_TYPE[key as keyof typeof USER_TYPE],
  title: USER_TYPE[key as keyof typeof USER_TYPE]
}));

export enum STATIC_SEARCH_PARAMS {
  redirectTo = "redirect_to",
  source = "source",
  pendingQuizSubmission = "pending_quiz_submission"
}

export const DETAIL_TAB_VISIBILITY_OPTIONS = Object.keys(DETAIL_TAB_VISIBILITY).map((key) => ({
  value: DETAIL_TAB_VISIBILITY[key as keyof typeof DETAIL_TAB_VISIBILITY],
  title:
    DETAIL_TAB_VISIBILITY[key as keyof typeof DETAIL_TAB_VISIBILITY].charAt(0).toUpperCase() +
    DETAIL_TAB_VISIBILITY[key as keyof typeof DETAIL_TAB_VISIBILITY].replace("_", " ").slice(1)
}));

export enum LOCK_LEVELS {
  PAGE = "page",
  TABLE = "table"
}

export const LOCK_LEVEL_OPTIONS = Object.keys(LOCK_LEVELS).map((key) => ({
  value: LOCK_LEVELS[key as keyof typeof LOCK_LEVELS],
  title: LOCK_LEVELS[key as keyof typeof LOCK_LEVELS]
}));

export const PUBLIC_ID_COLUMN = "public_id";

export enum FILTER_GROUP_TYPE {
  AND = "And",
  OR = "Or"
}

export const FILTER_GROUP_TYPE_OPTIONS = Object.keys(FILTER_GROUP_TYPE).map((key) => ({
  value: FILTER_GROUP_TYPE[key as keyof typeof FILTER_GROUP_TYPE],
  title: FILTER_GROUP_TYPE[key as keyof typeof FILTER_GROUP_TYPE]
}));

export const EMPTY_GROUP_FIELD = "(Empty)";

export enum COLUMN_RULE_TYPES {
  CONDITIONAL_FORMATTING = "formatting",
  COLUMN_VISIBILITY = "column_visibility",
  PAGES = "pages",
  TILES = "tiles",
  PAGE_ACTION_VISIBILITY = "page_action_visibility"
}

// This will be refactored to general UI_FILTER_TYPES
export enum APP_FILTER_TYPES {
  COLUMN_OPTIONS = "column_options",
  LOOKUP_PATH = "lookup_path",
  ADD_FILTER = "add_filter",
  ADD_TAB_FILTER = "add_tab_filter",
  PAGE_FILTER = "page_filter",
  SECTION_FILTER = "section_filter"
}

export enum COLUMN_RULE_PARENT_TYPES {
  UI_COLUMN_RULE = "ui_column_rule",
  UI_COLUMN_FILTER = "ui_column_filter"
}
export const SOLDAVE_GALLERY_COMPONENT_PAGE_PATH = "/gallery-component";

export const LOOKUP_PROPNAME_TYPES = {
  LOOKUP_PATH: "lookupPath",
  COLUMN_OPTIONS: "columnOptionsLookUp"
};

export enum PAGE_BULKACTION_PAYLOAD_TYPE {
  PAGE_COLUMN = "Page column",
  TEXT = "Text"
}

export const PAGE_BULKACTION_PAYLOAD_TYPE_OPTIONS = Object.keys(PAGE_BULKACTION_PAYLOAD_TYPE).map((key) => ({
  value: PAGE_BULKACTION_PAYLOAD_TYPE[key as keyof typeof PAGE_BULKACTION_PAYLOAD_TYPE],
  title: PAGE_BULKACTION_PAYLOAD_TYPE[key as keyof typeof PAGE_BULKACTION_PAYLOAD_TYPE]
}));

export enum FORM_DEFAULT_VALUE_TYPE {
  CUSTOM = "custom",
  LOOKUP_COLUMN = "lookup_column",
  BASIC_COLUMN = "basic_column"
}

export const ACTIVITY_TABLE = "activity";
export const CONVERSATIONS_TABLE = "conversations";

export enum APP_QUERY_PARAM_TYPES {
  FILTERS = "filters",
  SIDEBAR_PAGE = "side_page",
  SIDEBAR_RECORD = "side_record",
  SIDEBAR_FILE = "side_file",
  FORM_ID = "form_id",
  FORM_PAGEFORMID = "form_page_f_id",
  FORM_PAGE_ID = "form_page_id",
  FORM_PATH = "form_path",
  FORM_SIDEBAR = "form_sidebar",
  FORM_ACTION = "form_action",
  FORM_ADD = "form_add",
  FORM_ADD_TAB = "form_add_tab",
  FORM_PARENTID = "form_parentid",
  FORM_EXPANDED = "form_expanded",
  FORM_PARENTSLUG = "form_parentslug",
  VIEW_SECTION = "view_section",
  VIEW_SECTIONSLUG = "view_sectionslug",
  DEFAULT_TAB = "tab",
  VIEW = "view",
  EXPANDED = "expanded",
  CELL_SIDEPAGE = "cell_sidepageid", // Used to display special cell sidebar
  CELL_SIDERECORD = "cell_siderecordid",
  CELL_SIDEFILE = "cell_sidefile",
  CELL_TABID = "cell_tabid",
  CELL_SIDETYPE = "cell_sidetype",
  CELL_SIDETITLE = "cell_sidetitle",
  FORM_TITLE = "form_title",
  COLLAPSIBLE_VIEWER_REMOVE_ITEM = "collapsible_viewer_remove_item",
  SEARCH_QUERY = "search",
  FORM_TRIGGER = "form_trigger",
  SORT = "sort",
  MULTIPAGE_TAB = "multipage_tab",
  TAB_ID = "tab_id"
}

export enum COLUMN_OPTIONS_UI {
  SELECT = "select",
  COMMAND_MENU = "command_menu"
}

export const COLUMN_OPTIONS_UI_OPTIONS = [
  {
    value: COLUMN_OPTIONS_UI.SELECT,
    title: "Standard select dropdown"
  },
  {
    value: COLUMN_OPTIONS_UI.COMMAND_MENU,
    title: "Expanded command menu"
  }
];

export const MATRIX_COLUMN_HEADER_DEFAULT_HEIGHT = 150;

export enum AGGREGATE_FUNCTIONS {
  SUM = "sum",
  MAX = "max",
  MIN = "min",
  AVG = "avg",
  COUNT = "count"
}

export const AGGREGATE_FUNCTIONS_OPTIONS = Object.keys(AGGREGATE_FUNCTIONS).map((key) => ({
  value: AGGREGATE_FUNCTIONS[key as keyof typeof AGGREGATE_FUNCTIONS],
  title: AGGREGATE_FUNCTIONS[key as keyof typeof AGGREGATE_FUNCTIONS]
}));

export enum RELATION_TYPES {
  FOREIGN = "foreign",
  JOIN = "join"
}

export enum ERROR_TYPES {
  ADMIN = "Sold Ave Admin",
  STAFF_TABLE = "Sold Ave Table",
  STAFF_RECORD = "Sold Ave Record",
  CLIENT_PAGE = "Client Sold Ave Page",
  CLIENT_TILE = "Client Sold Ave Tile",
  COMPONENT = "Component",
  USER_AUTH = "Authentication",
  APP_ERROR = "Staff App Error",
  FORM = "Form",
  HOOKS = "Hooks",
  API = "API",
  PAGE_CONFIG = "Page Config",
  TESTS = "Tests"
}

export const VALID_STAFF_PATHS = ["admin", "comps", "p", "r", "t", "table", "tiles", "login"];

export const COMBINED_AGGREGATE_KEY = "combinedAggregate";

export enum FORM_TRIGGER_TYPES {
  SEARCH = "search"
}

export const BLOCKED_IPS = ["138.68.68.64", "152.32.217.126", "199.201.110.40", "52.179.175.39"];

export const TILES_MENU_ITEM_TABLE = "tiles";

export enum MULTI_PAGES_VIEW_LAYOUT {
  TABS = "tabs",
  GRID = "grid"
}
export const MULTI_PAGES_VIEW_LAYOUT_OPTIONS = [
  {
    value: MULTI_PAGES_VIEW_LAYOUT.TABS,
    title: "Tabs"
  },
  {
    value: MULTI_PAGES_VIEW_LAYOUT.GRID,
    title: "Grid"
  }
];

export type CellTypesWithUrl = CellType.PEOPLE | CellType.COMPANY | CellType.DEAL | CellType.PRODUCT;

export const CellTypesAvailableForUrl = [CellType.PEOPLE, CellType.COMPANY, CellType.DEAL, CellType.PRODUCT];

export const NOTES_LINKS_TABLE = "notes_links";
export const USER_TABLE_NAME = "contacts";
export const DEALS_TABLE_NAME = "deals";

export const EXTERNAL_FOREIGN_KEY_DISPLAY_TABLES = [CONVERSATIONS_TABLE];

// ##HARDCODED
export const CellTypeDefaultPageTablename: Record<CellTypesWithUrl, string> = {
  [CellType.PEOPLE]: USER_TABLE_NAME,
  [CellType.COMPANY]: "companies",
  [CellType.DEAL]: "deals",
  [CellType.PRODUCT]: "products"
};

// ##HARDCODED - View names
export const DB_VIEWS = ["view_all_functions", "view_tasks_task_libary"];

export const SKIP_WORKSPACE_ID_FILTER_KEY = "skip_workspace_id_filter";

export enum TILE_DISABLE_PAGE_RENDER {
  TYPE = "type"
}

export const IS_DELETED_PAGE_FILTER: TableFilterType = {
  id: "filter_is_deleted",
  filterField: "is_deleted",
  filterValue: false,
  filterOperator: FILTER_OPERATOR.EQUALS
};

// ##HARDCODED
export const ADMIN_SEARCH_COLUMNS: RecordItem = {
  "admin-tables": ["path", "title", "table_name"]
};

export const ADMIN_SEARCH_TABLES: RecordItem = {
  "admin-tables": "pages"
};

export enum ROLE_JOIN_TABLE {
  COLUMNS = "ui_columns_roles",
  MENU_ITEMS = "ui_menu_items_roles",
  PAGE_ACTIONS = "ui_pages_actions_roles",
  MENU_ACTIONS = "ui_menu_actions_roles",
  VIEWS = "ui_views_roles",
  COLUMN_SECTIONS = "ui_column_sections_roles",
  PAGE_VIEWS = "ui_pages_views_roles"
}

export enum ROLE_JOIN_KEY_NAME {
  COLUMNS = "column_id",
  MENU_ITEMS = "ui_menu_item_id",
  PAGE_ACTIONS = "ui_pages_actions_id",
  MENU_ACTIONS = "ui_menu_actions_id",
  VIEWS = "ui_view_id",
  COLUMN_SECTIONS = "ui_column_sections_id",
  PAGE_VIEWS = "ui_pages_views_id"
}

export const CALIFORNIA_LONGITUDE = -118.24598772533083;
export const CALIFORNIA_LATITUDE = 34.047362642841264;
